import type { MouseEventHandler, ReactNode } from 'react'
import clsx from 'clsx'
import { brandName } from '@brand/config/brand-config'
import { Logo } from '@brand/slots/logo/logo'
import { NavLinks } from '@brand/slots/nav-links/nav-links'
import { ReactComponent as BackRedesignIcon } from '@brand/icons/back-redesign.svg'
import { PageHeaderUserMenu } from '../../../../features/page-header/page-header-user-menu'
import type { PageHeader_QueryFragment } from '../../../../features/page-header/__generated__/page-header.gql'
import type { DrawerMenuLinks } from '../../../../features/page-header/drawer-menu/drawer-menu.types'
import { SkipLink } from '../../../../components/skip-link/skip-link'
import { PageHeaderAuthTriggers } from './page-header-auth-triggers'
import { DrawerMenu } from './drawer-menu/drawer-menu'
import styles from './page-header.module.css'

export type PageHeaderOwnProps = {
  isFixed?: boolean
  /** Whether to show the back button on screens <= 1024px */
  showBackButton?: boolean
  mobileContent?: ReactNode
  marketTrendsUrl?: string | null
  seoLinks?: DrawerMenuLinks
  onBackClick?: MouseEventHandler<HTMLButtonElement>
  theme?: {
    pageHeaderClassname?: string
    pageHeaderBackButtonClassname?: string
  }
}

export type PageHeaderProps = PageHeaderOwnProps & PageHeader_QueryFragment

const possibleSkipLinkTargetSelector = ['a', 'button', 'input', 'textarea']
  .map((str) => `main ${str}`)
  .join(',')

export function PageHeader(props: PageHeaderProps) {
  return (
    <header
      className={clsx(
        styles.pageHeader,
        props.isFixed && styles.pageHeaderFixed
      )}
      data-tid="header"
      data-tag_section="header"
    >
      <div className={styles.pageHeaderLeft}>
        <SkipLink
          className={styles.pageHeaderSkipLink}
          selector={possibleSkipLinkTargetSelector}
          id=""
          data-tid="skip-link"
        />
        <div className={styles.pageHeaderButtons}>
          {props.showBackButton && (
            <button
              className={styles.pageHeaderBack}
              aria-label="Back to search result"
              onClick={props.onBackClick}
              data-tag_item="back-button-arrow"
            >
              <BackRedesignIcon aria-hidden data-tid="back-button-arrow" />
            </button>
          )}
          <DrawerMenu />
        </div>
        <a
          href="/"
          data-tid="logo"
          className={styles.pageHeaderLogoLink}
          aria-label={brandName}
          data-tag_item="rent_logo"
        >
          <Logo className={styles.pageHeaderLogo} />
        </a>
      </div>
      {props.mobileContent && (
        <div className={styles.pageHeaderMobileContent}>
          {props.mobileContent}
        </div>
      )}

      <nav data-tid="header-nav">
        <ul
          className={clsx(styles.pageHeaderLinks, {
            [styles.pageHeaderLinksHiddenOnMobile]:
              props.mobileContent || props.showBackButton,
          })}
        >
          <NavLinks
            mobileContent={props.mobileContent}
            location={props.location}
            marketTrendsUrl={props?.marketTrendsUrl}
          />
        </ul>
      </nav>

      <div className={styles.placeholder}>
        {!props.mobileContent ? (
          <>
            <PageHeaderAuthTriggers />
            <PageHeaderUserMenu renderRedesignedMenu />
          </>
        ) : null}
      </div>
    </header>
  )
}
